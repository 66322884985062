import React from 'react'
import { Link, useStaticQuery, navigate } from "gatsby"
import PropTypes from 'prop-types'
import SwipeableViews from 'react-swipeable-views'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Button,
  CircularProgress,
  IconButton,
  Backdrop
} from '@material-ui/core'

import GetAppIcon from '@material-ui/icons/GetApp';
import OfflinePinIcon from '@material-ui/icons/OfflinePin';

const windowGlobal = typeof window !== 'undefined' && window

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  tabsAB: {
    padding: "0 !important",
    width: 1000
  },
  nested: {
    paddingLeft: theme.spacing(4)
  },
  rtl: {
    direction: "rtl"
  },
  tabTitle: {
    color: "black",
    fontSize: 16
  },
  tabsLists: {
    '& a': {
      textDecoration: "none",
      color: "#000"
    },
    '& div:nth-child(even).listitems': {
      backgroundColor: "rgba(48, 139, 171, 0.23)"
    },
    '& .listitemsDiv': {
      display: "inline-flex",
      float: "left",
    },
    '& .listitemsAncher': {
      width: "100%"
    },
    '& .tabsListsText': {
      paddingTop: 10
    },
  },
  tabListsRtl: {
    '& .listitems': {
      paddingLeft: 16,
      paddingRight: 32,
    },
    '& .tabsListsText': {
      textAlign: "right",
      paddingTop: 10
    },
    '& .tabsLists': {
      direcition: "rtl",
    },
    '& .listitemsDiv': {
      display: "inline-flex",
      float: "right",
    },
  },
  tabsListsIconNo: {
    '&.tabsListsIconNo': {
      // marginRight: 20,
      textAlign: "center",
    },

  },
  tabsListsText: {
    marginLeft: 20,
  },
  listItemRtl: {
    direction: "rtl",
    '& .tabsListsIconNo': {
      marginLeft: 20,
    },
  },
  tabsListsContainer: {
    '&>.MuiBox-root': {
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 5,
    }
  },
  loadmoreBtnWrap: {
    textAlign: 'center'
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}



export default function TabsForHome({ locale, lang }) {

  const classes = useStyles();
  const theme = useTheme();

  const [openDrop, setOpenDrop] = React.useState(false)
  const [pagesStatus, setPagesStatus] = React.useState([]);
  const [surahsStatus, setSurahsStatus] = React.useState([]);
  const [partsStatus, setPartsStatus] = React.useState([]);
  const [value, setValue] = React.useState(0);
  const [loadedPages, setLoadedPages] = React.useState(15);
  const [loadedSurahs, setLoadedSurahs] = React.useState(15);
  const [loadedParts, setLoadedParts] = React.useState(10);

  

  const { allStrapiSurahs } = useStaticQuery(
    graphql`
      query {
        allStrapiSurahs {
          nodes {
            name {
              lang {
                  lang
              }
              text
            }
            surah_number
          }
        }
      }
    `
  )

  const allSurahs = []
  for (const surah of allStrapiSurahs.nodes) {
    let mlText = surah.name.filter(x => x.lang.lang === lang)[0]
    if (mlText) {
      mlText = mlText.text
    } else {
      mlText = locale['Surah'][lang] + ' ' + surah.surah_number
    }
    allSurahs.push({
      title: mlText,
      number: surah.surah_number
    })
  }


  const allParts = []
  for (let i = 1; i <= 30; i++) {
    allParts[i] = { identifier: locale['Part'][lang], number: i }
  }

  const handlePageLoadMore = () => {
    setLoadedPages(loadedPages + 15)
  }

  const handleSurahLoadMore = () => {
    setLoadedSurahs(loadedSurahs + 15)
  }

  const handlePartLoadMore = () => {
    setLoadedParts(loadedParts + 15)
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = index => {
    setValue(index);
  };

  const direction = lang === "ar" ? "rtl" : "ltr"
  const listDirection = lang === "ar" ? "tabListsRtl" : "tabListsLtr"
  const listItem = lang === "ar" ? "listItemRtl" : "listItemLtr"

  // Get reader for download btn's
  let reader = 'reader'
  if ( lang === "ar" ) {
    if (windowGlobal.localStorage.getItem('reader')) {
      reader = windowGlobal.localStorage.getItem('reader')
    } else {
      reader = 'العفاسي'
    }
  }


  const downloadThisPage = (value, num) => {
    
    setPagesStatus([...pagesStatus, num])
    caches.open('gatsby-plugin-offline-runtime').then(function(cache) {

      fetch(value)
      .then((response) => {
        if (!response.ok) {
          throw new TypeError('bad response status');
        }
        return cache.put(value, response)
      })
      .then((data) => {
        windowGlobal.localStorage.setItem('page-'+lang+'-'+num, 'downloaded')
        setPagesStatus(pagesStatus)
      })

    })
  }


  const downloadThisSurah = (value, num) => {
    
    setSurahsStatus([...surahsStatus, num])
    caches.open('gatsby-plugin-offline-runtime').then(function(cache) {
      //cache.add(value)
      fetch(value)
      .then((response) => {
        if (!response.ok) {
          throw new TypeError('bad response status');
        }
        return cache.put(value, response)
      })
      .then((data) => {
        windowGlobal.localStorage.setItem('surah-'+lang+'-'+num, 'downloaded')
        setSurahsStatus(surahsStatus)
      })

    })
  }


  const downloadThisPart = (value, num) => {
    
    setPartsStatus([...partsStatus, num])
    caches.open('gatsby-plugin-offline-runtime').then(function(cache) {

      fetch(value)
      .then((response) => {
        if (!response.ok) {
          throw new TypeError('bad response status');
        }
        return cache.put(value, response)
      })
      .then((data) => {
        windowGlobal.localStorage.setItem('part-'+lang+'-'+num, 'downloaded')
        setPartsStatus(partsStatus)
      })

    })
  }

  const allPages = []
  for (let i = 1; i <= 604; i++) {
    allPages[i] = { identifier: locale['Page'][lang], number: i }

  }

  const handleNav = (url, event) => {
    event.preventDefault()
    setOpenDrop(true)
    navigate(url)
  }

  return (
    <div className={`${classes.root} ${classes[direction]}`} >
      <Backdrop className={classes.backdrop} open={openDrop}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <AppBar position="static" color="default" >
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab className={`${classes.tabTitle} tabTitle`} label={locale['allPages'][lang]} {...a11yProps(0)} />
          <Tab className={`${classes.tabTitle} tabTitle`} label={locale['allSurahs'][lang]} {...a11yProps(1)} />
          <Tab className={`${classes.tabTitle} tabTitle`} label={locale['allParts'][lang]} {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >

        <TabPanel value={value} index={0} dir={theme.direction} className={classes.tabsListsContainer}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={`${classes.tabsLists} ${classes[listDirection]} tabsLists`}
          >
            {allPages.slice(0, loadedPages).map((option) => (
                <ListItem disableRipple button className={`${classes.nested} ${classes[listItem]} listitems`} key={option.number}>
                  <Link onClick={(event) => handleNav('/p/' + option.number, event)} to={'/p/' + option.number} className="listitemsAncher">
                    <div className="listitemsDiv">
                      <div className={`${classes.tabsListsIconNo} tabsListsIconNo`}>
                        {option.number}
                      </div>
                      <ListItemText className={`${classes.tabsListsText} tabsListsText`}>{option.identifier + ' ' + option.number}</ListItemText>
                    </div>
                  </Link>

                { windowGlobal && windowGlobal.localStorage.getItem('page-'+lang+'-'+option.number) === 'downloaded' ? <OfflinePinIcon color="primary" /> :
                  <IconButton 
                    onClick={(e) => downloadThisPage('/content/pages/' + lang + '/' + reader + '/' + option.number + '.mp3', option.number)}
                    size="small"
                  >
                    {pagesStatus.includes(option.number) && <CircularProgress size={14} />}
                    {!pagesStatus.includes(option.number) && <GetAppIcon />}
                  </IconButton>
                }
                </ListItem>
            ))}
          </List>
          <Box className={classes.loadmoreBtnWrap} p={2}>
            <Button variant="outlined" color="primary" onClick={handlePageLoadMore}>
              {locale['Load More'][lang]}
            </Button>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction} className={classes.tabsListsContainer}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={`${classes.tabsLists} ${classes[listDirection]} tabsLists`}
          >
            {allSurahs.slice(0, loadedSurahs).map((option) => (
              <ListItem disableRipple button className={`${classes.nested} ${classes[listItem]} listitems`} key={option.number}>
                <Link onClick={(event) => handleNav('/s/' + option.number, event)} to={'/s/' + option.number} className="listitemsAncher">
                  <div className="listitemsDiv">
                    <div className={`${classes.tabsListsIconNo} tabsListsIconNo`}>
                      {option.number}
                    </div>
                    <ListItemText className={`${classes.tabsListsText} tabsListsText`}>{option.title}</ListItemText>
                  </div>
                </Link>
                { windowGlobal && windowGlobal.localStorage.getItem('surah-'+lang+'-'+option.number) === 'downloaded' ? <OfflinePinIcon color="primary" /> :
                  <IconButton 
                    onClick={(e) => downloadThisSurah('/content/surahs/' + lang + '/' + reader + '/' + option.number + '.mp3', option.number)}
                    size="small"
                  >
                    {surahsStatus.includes(option.number) && <CircularProgress size={14} />}
                    {!surahsStatus.includes(option.number) && <GetAppIcon />}
                  </IconButton>
                }
              </ListItem>
            ))}
          </List>
          <Box className={classes.loadmoreBtnWrap} p={2}>
            <Button variant="outlined" color="primary" onClick={handleSurahLoadMore}>
              {locale['Load More'][lang]}
            </Button>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2} className={classes.tabsListsContainer}>
          <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={`${classes.tabsLists} ${classes[listDirection]} tabsLists`}
          >
            {allParts.slice(0, loadedParts).map((option) => (
                <ListItem disableRipple button className={`${classes.nested} ${classes[listItem]} listitems`} key={option.number}>
                  <Link onClick={(event) => handleNav('/pr/' + option.number, event)} to={'/pr/' + option.number} className="listitemsAncher">
                    <div className="listitemsDiv">
                      <div className={`${classes.tabsListsIconNo} tabsListsIconNo`}>
                        {option.number}
                      </div>
                      <ListItemText className={`${classes.tabsListsText} tabsListsText`}>{option.identifier + ' ' + option.number}</ListItemText>
                    </div>
                  </Link>
                  { windowGlobal && windowGlobal.localStorage.getItem('part-'+lang+'-'+option.number) === 'downloaded' ? <OfflinePinIcon color="primary" /> :
                  <IconButton 
                    onClick={(e) => downloadThisPart('/content/parts/' + lang + '/' + reader + '/' + option.number + '.mp3', option.number)}
                    size="small"
                  >
                    {partsStatus.includes(option.number) && <CircularProgress size={14} />}
                    {!partsStatus.includes(option.number) && <GetAppIcon />}
                  </IconButton>
                 }
                </ListItem>
            ))}
          </List>

          <Box className={classes.loadmoreBtnWrap} p={2}>
            <Button variant="outlined" color="primary" onClick={handlePartLoadMore}>
              {locale['Load More'][lang]}
            </Button>
          </Box>
        </TabPanel>
      </SwipeableViews>
    </div >
  );
}
// export default TabsForHome