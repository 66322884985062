import React from "react"
import { useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TabsForHome from "../components/tabs"
import { Container } from "@material-ui/core"
// import { locale } form "layout"

const windowGlobal = typeof window !== "undefined" && window

const DownloadsPage = () => {
  const { allStrapiLocale, allStrapiLangs } = useStaticQuery(
    graphql`
      query {
        allStrapiLangs {
          nodes {
            lang
            lang_name
            flag {
              publicURL
            }
          }
        }
        allStrapiLocale {
          nodes {
            original
            text {
              lang {
                lang
              }
              text
            }
          }
        }
      }
    `
  )

  let lclLang = ""

  if (windowGlobal) {
    if (windowGlobal.localStorage.getItem("language")) {
      lclLang = windowGlobal.localStorage.getItem("language")
    } else {
      lclLang = navigator.language.substring(0, 2)
      windowGlobal.localStorage.setItem("language", lclLang)
    }
  }

  const [lang, setLang] = React.useState(lclLang)

  const dataNew = {}
  // console.log(allStrapiLangs.nodes[lang].lang)
  for (const value of allStrapiLangs.nodes) {
    // let flagObj = {}
    dataNew[value.lang] = { publicURL: value.flag.publicURL, lang: { lang_name: value.lang_name } }
    // dataNew[value.lang] = flagObj
    // for (const value2 of value.lang) {
    //   flagObj[value2.flag] = value2.flag
    // }
    // dataNew[value.langsFlagUrl] = flagObj
  }
  // console.log(dataNew)
  // console.log(allStrapiLangs.nodes.dataNew)

  const locale = {}
  for (const value of allStrapiLocale.nodes) {
    let textObj = {}
    for (const value2 of value.text) {
      textObj[value2.lang.lang] = value2.text
    }
    locale[value.original] = textObj
  }

  const changeLang = (lang) => {
    setLang(lang)
    windowGlobal.localStorage.setItem("language", lang)
    windowGlobal.localStorage.removeItem("reader")
  }

  const allPages = []
  for (let i = 1; i <= 604; i++) {
    allPages[i] = { identifier: locale["Page"][lang], number: i }
  }

  const allParts = []
  for (let i = 1; i <= 30; i++) {
    allParts[i] = { identifier: locale["Part"][lang], number: i }
  }

  return (
    <Layout lang={lang} onLangChange={changeLang} locale={locale} dataNew={dataNew}>
      <SEO title="Downloads" />
      <Container maxWidth="md">
        <TabsForHome locale={locale} lang={lang} allPages={allPages} allParts={allParts} />
      </Container>
      {/* <h1>Hi people</h1> */}
      {/* <p><Link to="/p/1">Go to pages</Link></p> */}
      {/* <p><Link to="/ap/1">Go to arabic pages</Link></p> */}
      {/* <p><Link to="/pr/1">Go to parts</Link></p> */}
      {/* <p><Link to="/apr/1">Go to arabic parts</Link></p> */}
      {/* <p><Link to="/s/1">Go to surahs</Link></p> */}
      {/* <p><Link to="/as/1">Go to arabic surahs</Link></p> */}
    </Layout>
  )
}

export default DownloadsPage
